const initState = {
  page: '*',
  type: '',
  image: '',
};
const funcToExport = (state = initState, action) => {
  switch (true) {
    case action.type === 'ROW_BUILD':
      let payload = action.payload;
      if (state !== '') {
        // adjust payload, if necessary, to toggle selection
        if (payload.category === 'page' && payload.page === state.page) {
          // unselect page
          payload.page = '*';
          payload.type = '';
          payload.image = '';
        } else if (payload.category === 'type' && payload.type === state.type) {
          payload.type = '*';
          payload.image = '';
        } else if (
          payload.category === 'image' &&
          payload.image === state.image
        ) {
          payload.image = '*';
        }
      }
      return payload;
    default:
      return state;
  }
};
export default funcToExport;
