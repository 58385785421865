const initState = false;
const funcToExport = (state = initState, action) => {
  switch (true) {
    case action.type === 'ROW_REVEAL':
      return action.payload;
    default:
      return state;
  }
};
export default funcToExport;
