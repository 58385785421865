const initState = 'setup';
const funcToExport = (state = initState, action) => {
  switch (true) {
    case action.type === 'TAB_CHOICE':
      return action.payload;
    default:
      return state;
  }
};
export default funcToExport;
