import caroFlagReducer from './caroFlagReducer';
import { combineReducers } from 'redux';
import faBodyReducer from './faBodyObjReducer';
import footerReducer from './footerObjReducer';
import homeReducer from './homeObjReducer';
import pagesReducer from './pagesObjReducer';
import pagesPreviewReducer from './pagesPreviewObjReducer';
import rowBuildReducer from './rowBuildReducer';
import rowControlReducer from './rowControlReducer';
import rowRevealReducer from './rowRevealReducer';
import settingsReducer from './settingsObjReducer';
import tabChoiceReducer from './tabChoiceReducer';
import typesImageViewedReducer from './typesImageViewedObjReducer';
import typeOrdrReducer from './typeOrdrReducer';

const rootReducer = combineReducers({
  caroFlag: caroFlagReducer,
  faBodyObj: faBodyReducer,
  footerObj: footerReducer,
  homeObj: homeReducer,
  pagesObj: pagesReducer,
  pagesPreviewObj: pagesPreviewReducer,
  rowBuildObj: rowBuildReducer,
  rowControlObj: rowControlReducer,
  rowRevealObj: rowRevealReducer,
  settingsObj: settingsReducer,
  tabChoice: tabChoiceReducer,
  typesImageViewedObj: typesImageViewedReducer,
  typeOrdr: typeOrdrReducer,
});

export default rootReducer;
