const initState = '';
const funcToExport = (state = initState, action) => {
  switch (action.type) {
    case 'FABODY_OBJECT':
      return action.payload;
    default:
      return state;
  }
};
export default funcToExport;
